<template>
  <div class="page">
    <div id="barChart" class="cylindricalchart"></div>
  </div>
</template>
<script>
  export default {
    props: {
      parkRecord: {
        default: function () {
          return {}
        }
      },
      type: {

      }
    },
    data() {
      return {
//        data1: ['郑州', '北京', '菏泽', '山东', '上海', '大连', '保定'],
//        data2: ['10', '30', '0', '100', '50', '70', '50']
        data1: [],
        data2: []
      }
    },
    watch: {
      parkRecord: {
        handler (value) {
          for (var i = 0; i < value.length; i++) {
            this.data1.push(value[i].areaName);
            this.data2.push(value[i].exitCount);
          }
          if (this.data1.length > 7) {
            this.drawGraph(true);
          } else {
            this.drawGraph(false);
          }
        },
        deep: true
    }
    },
    methods: {
      drawGraph (show) {
        let option = {
          title: {
            // text: this.type == 'c2' ? '' : '单位：个',
            text: '单位：个',
            color: '#9AA4B3',
            textStyle: {
              fontSize: 12,
              color: '#9aa4b3'
            }
          },
          dataZoom: [{
            startValue: 0, // 从头开始
            endValue: 6, // 一次性展示7个
            type: 'slider',
            show: show,
            xAxisIndex: [0],
            handleSize: 0, // 滑动条的 左右2个滑动条的大小
            height: 8, // 组件高度
            bottom: 20, // 右边的距离
            borderColor: "white",
            fillerColor: 'rgba(0, 0, 0, 0.3)',
            borderRadius: 5,
//            backgroundColor: '#fff', // 两边未选中的滑动条区域的颜色
            showDataShadow: false, // 是否显示数据阴影 默认auto
            showDetail: false, // 即拖拽时候是否显示详细数值信息 默认true
            realtime: true, // 是否实时更新
          }],
          color: ['#5B8FF9'],
          tooltip: {
            trigger: 'axis',
            axisPointer: {            // 坐标轴指示器，坐标轴触发有效
              type: 'shadow',
              lineStyle: {
                color: '#5B8FF9'
              },
              shadowStyle: {
                opacity: 0.15,
                width: 1,
                color: '#4365F2'
              }
            },
            // backgroundColor: 'rgba(0, 0, 0, 0.5)',
            formatter: (params) => {
            let tipText =
              `
                <p><span style="position: absolute;left: 11px;"></span>城市：${params[0].axisValue}</p>
                <p><span style=""></span>停车记录：${params[0].value}</p>
              `
            return tipText;
      }
          },
          toolbox: {
          },
          legend: {
          },
        xAxis: {
          type: 'category',
          data: this.data1,
          axisLine: {
            show: false,
            lineStyle: {
              color: '#9AA4B3'
            }
          },
          axisLabel: {
            interval: 0
          },
          axisTick: {
            show: false,
            alignWithLabel: 40
          },
          // 展示网格线
          splitLine: {
            show: false
          }
        },
        yAxis: [
          {
            type: 'value',
            axisTick: {
              show: false
            },
            axisLine: {
              show: false,
              lineStyle: {
                color: '#9AA4B3'
              }
            },
            // 展示网格线
            splitLine: {
              show: false
            }
          }
        ],
          series: [{
//            name: '收入（元）',
            type: 'bar',
            itemStyle: {
                normal: {
                  shadowBlur: 2,
                  shadowColor: 'rgba(83,139,255,0.40)',
//                  shadowOffsetX: 2,
//                  shadowOffsetY: 2,
                  barBorderRadius: [4, 4, 0, 0],
                 }
            },
            barWidth: '24',
             data: this.data2
          }]
        };
        let circularChart
        circularChart = this.$echarts.init(document.getElementById('barChart'));
        circularChart.setOption(option);
      }
    },
    mounted() {
    }
  }
</script>
<style scoped lang="stylus" rel="stylesheet/stylus">
  .page
    color #9aa4b3
  .head
    margin 10px 0px
  .cylindricalchart
    width 100%
    height 277px
    overflow hidden
</style>

